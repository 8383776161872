.app {
  height: 100%;
}

.app-header {
  height: 200px;
  text-align: center;
  font-family: 'recoletamedium';
  font-size: 40px;
  padding: 40px;
}

.app-intro {
  font-size: large;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('./styles/fonts/SourceSansPro-Bold.otf');
}

.logo-premium {
  background-image: url("/src/assets/images/logo-premium.svg");
  width: 53px;
  height: 53px;
  margin: 0 auto 20px;
}

.title {
  line-height: 40px;
}

@media (max-width: 576px) {
  .title {
    font-size: 30px;
  }
}
