:root {
  --text: #253F4E;
  --dark-green: #1E3C24;
  --light-green: #92A96B;
  --dark-blue: #143E56;
  --light-blue: #358EC1;
  --camel: #E2D9BB;
  --camel-box-text: #1E3C24;

  --big-size: 80px;
  --medium-size: 60px;
  --small-size: 40px;
  --xsmall-size: 30px;
  --button-size: 20px;

  --modal-background: #FFFFFF;
  --body-background: #E0EFF0;
}

@media (max-width: 576px) {
  :root {
    --big-size: 40px;
    --medium-size: 30px;
    --small-size: 20px;
    --xsmall-size: 15px;
    --button-size: 12px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  :root {
    --big-size: 60px;
    --medium-size: 40px;
    --small-size: 30px;
    --xsmall-size: 20px;
    --button-size: 12px;
  }
}

@font-face {
  font-family: 'recoletamedium';
  src: url('styles/fonts/recoleta-medium-webfont.woff2') format('woff2'),
    url('styles/fonts/recoleta-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'recoleta_altbold';
  src: url('styles/fonts/recoletaalt-bold-webfont.woff2') format('woff2'),
    url('styles/fonts/recoletaalt-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

*:focus {
  outline: none;
}

body,
html,
#root {
  height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text);
  background-color: var(--body-background) !important;
  background-image: url("assets/images/background.svg");
  background-position: center;
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-button {
  padding: 10px 20px;
  color: white;
  background-color: var(--light-green);
  cursor: pointer;
  margin: 0 0 30px 0;
  font-family: 'recoletamedium';
  font-size: var(--button-size);
}

.page-button:hover {
  color: white;
}

#calendar-animation {
  height: calc(100% - 230px);
  padding-bottom: 30px;
}

@media (max-width: 576px) {
  #calendar-animation {
    min-height: 480px;
  }
}

@media (min-width: 577px) {
  #calendar-animation {
    min-height: 768px;
  }
}

@media (min-width: 768px) {
  #calendar-animation {
    min-height: 992px;
  }
}

#lottie-animation {
  height: 100%;
  position: absolute;
}

#calendar {
  display: grid;
  grid-template-columns: repeat(6, 16%);
  grid-template-rows: repeat(6, 16%);
  gap: .6%;
  height: 100%;
  max-width: 992px;
}

#calendar>div .front {
  border-radius: 8px;
  font-family: 'recoleta_altbold';
  padding: 16px;
  color: white;
  width: 100%;
  height: 100%;
}

@media (max-width: 576px) {
  #calendar>div .front {
    padding: 8px;
    background-size: contain;
  }
}

#calendar>div .front .text {
  font-family: 'recoletamedium';
  font-size: var(--small-size);
  line-height: var(--small-size);
  margin-top: 20px;
}

@media (max-width: 576px) {
  #calendar>div .front .text {
    font-size: var(--xsmall-size);
    line-height: var(--xsmall-size);
  }
}

#calendar>div:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}

#calendar>div:nth-child(1) .front {
  background-color: var(--dark-green);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('assets/images/day1.svg');
  background-repeat: no-repeat;
  background-position: center;
}

#calendar>div:nth-child(2) {
  grid-column: 2 / 4;
  grid-row: 1;
}

#calendar>div:nth-child(2) .front {
  grid-column: 2 / 4;
  grid-row: 1;
  background-color: var(--light-blue);
  font-size: var(--big-size);
  line-height: var(--big-size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('assets/images/day2.svg');
  background-position: -12px;
}

#calendar>div:nth-child(3) {
  grid-column: 4;
  grid-row: 1;
}

#calendar>div:nth-child(3) .front {
  background-color: var(--light-green);
  font-size: var(--small-size);
  line-height: var(--small-size);
  text-align: left;
  background-image: url('assets/images/day3.svg');
  background-repeat: no-repeat;
  background-position: center;
}

#calendar>div:nth-child(4) {
  grid-column: 5;
  grid-row: 1 / 3;
}

#calendar>div:nth-child(4) .front {
  background-color: var(--dark-green);
  font-size: var(--big-size);
  line-height: var(--big-size);
  background-image: url('assets/images/day4.svg');
  display: flex;
  align-items: center;
  justify-content: center;
}

#calendar>div:nth-child(5) {
  grid-column: 6;
  grid-row: 1;
}

#calendar>div:nth-child(5) .front {
  background-color: var(--light-blue);
  font-size: var(--small-size);
  line-height: var(--small-size);
  text-align: left;
  background-image: url('assets/images/day5.svg');
  background-repeat: no-repeat;
  background-position: 75% 75%;
}

#calendar>div:nth-child(6) {
  grid-column: 1;
  grid-row: 2 / 4;
}

#calendar>div:nth-child(6) .front {
  background-color: var(--light-green);
  font-size: var(--big-size);
  line-height: var(--big-size);
  background-image: url('assets/images/day6.svg');
  background-repeat: no-repeat;
  background-position: center 75%;
}

#calendar>div:nth-child(7) {
  grid-column: 2;
  grid-row: 2;
}

#calendar>div:nth-child(7) .front {
  background-color: var(--dark-green);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  background-image: url('assets/images/day7.svg');
  display: flex;
  align-items: center;
  justify-content: center;
}

#calendar>div:nth-child(8) {
  grid-column: 3 / 5;
  grid-row: 2;
}

#calendar>div:nth-child(8) .front {
  background-color: var(--camel);
  color: var(--camel-box-text);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  text-align: left;
  background-image: url('assets/images/day8.svg');
  background-repeat: no-repeat;
  background-position: 75% center;
}

#calendar>div:nth-child(9) {
  grid-column: 6;
  grid-row: 2;
}

#calendar>div:nth-child(9) .front {
  background-color: var(--light-green);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('assets/images/day9.svg');
  background-repeat: no-repeat;
  background-position: center;
}

#calendar>div:nth-child(10) {
  grid-column: 2;
  grid-row: 3;
}

#calendar>div:nth-child(10) .front {
  background-color: var(--light-blue);
  font-size: var(--small-size);
  line-height: var(--small-size);
  text-align: left;
  background-image: url('assets/images/day10.svg');
  background-repeat: no-repeat;
  background-position: 75% 75%;
}

#calendar>div:nth-child(11) {
  grid-column: 3;
  grid-row: 3;
}

#calendar>div:nth-child(11) .front {
  background-color: var(--dark-blue);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('assets/images/day11.svg');
  background-repeat: no-repeat;
  background-position: 10% top;
}

#calendar>div:nth-child(12) {
  grid-column: 4;
  grid-row: 3;
}

#calendar>div:nth-child(12) .front {
  background-color: var(--dark-green);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  background-image: url('assets/images/day12.svg');
  background-position: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#calendar>div:nth-child(13) {
  grid-column: 5;
  grid-row: 3 / 5;
}

#calendar>div:nth-child(13) .front {
  background-color: var(--light-green);
  font-size: var(--small-size);
  line-height: var(--small-size);
  text-align: right;
  background-image: url('assets/images/day13.svg');
  background-repeat: no-repeat;
  background-position: center 75%;
}

#calendar>div:nth-child(14) {
  grid-column: 6;
  grid-row: 3;
}

#calendar>div:nth-child(14) .front {
  background-color: var(--dark-blue);
  font-size: var(--small-size);
  line-height: var(--small-size);
  text-align: left;
  background-image: url('assets/images/day14.svg');
  background-repeat: no-repeat;
  background-position: 75% 75%;
}

#calendar>div:nth-child(15) {
  grid-column: 1 / 3;
  grid-row: 4;
}

#calendar>div:nth-child(15) .front {
  background-color: var(--camel);
  color: var(--camel-box-text);
  font-size: var(--big-size);
  line-height: var(--big-size);
  background-image: url('assets/images/day15.svg');
  background-position: 10px 10px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

#calendar>div:nth-child(16) {
  grid-column: 3;
  grid-row: 4 / 6;
}

#calendar>div:nth-child(16) .front {
  background-color: var(--light-green);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  text-align: right;
  background-image: url('assets/images/day16.svg');
  background-repeat: no-repeat;
  background-position: center;
}

#calendar>div:nth-child(17) {
  grid-column: 4;
  grid-row: 4;
}

#calendar>div:nth-child(17) .front {
  background-color: var(--light-blue);
  font-size: var(--small-size);
  line-height: var(--small-size);
  text-align: right;
  background-image: url('assets/images/day17.svg');
  background-repeat: no-repeat;
  background-position: 25% 75%;
}

#calendar>div:nth-child(18) {
  grid-column: 6;
  grid-row: 4;
}

#calendar>div:nth-child(18) .front {
  background-color: var(--camel);
  color: var(--camel-box-text);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  background-image: url('assets/images/day18.png');
  display: flex;
  align-items: center;
  justify-content: center;
}

#calendar>div:nth-child(19) {
  grid-column: 1;
  grid-row: 5;
}

#calendar>div:nth-child(19) .front {
  background-color: var(--light-green);
  font-size: var(--small-size);
  line-height: var(--small-size);
  text-align: left;
  background-image: url('assets/images/day19.svg');
  background-repeat: no-repeat;
  background-position: 75% 75%;
}

#calendar>div:nth-child(20) {
  grid-column: 2;
  grid-row: 5;
}

#calendar>div:nth-child(20) .front {
  background-color: var(--dark-blue);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('assets/images/day20.svg');
  background-repeat: no-repeat;
  background-position: center;
}

#calendar>div:nth-child(21) {
  grid-column: 4 / 6;
  grid-row: 5;
}

#calendar>div:nth-child(21) .front {
  background-color: var(--camel);
  color: var(--camel-box-text);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  background-image: url('assets/images/day21.png');
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
}

#calendar>div:nth-child(22) {
  grid-column: 1 / 3;
  grid-row: 6;
}

#calendar>div:nth-child(22) .front {
  background-color: var(--light-blue);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  display: flex;
  align-items: center;
  justify-content: left;
  background-image: url('assets/images/day22.svg');
  background-repeat: no-repeat;
  background-position: 90% top;
}

#calendar>div:nth-child(22) .front .day {
  margin-left: 15%;
}

#calendar>div:nth-child(23) {
  grid-column: 3 / 5;
  grid-row: 6;
}

#calendar>div:nth-child(23) .front {
  background-color: var(--dark-green);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  background-image: url('assets/images/day23.png');
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
}

#calendar>div:nth-child(24) {
  grid-column: 5;
  grid-row: 6;
}

#calendar>div:nth-child(24) .front {
  background-color: var(--light-green);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  color: var(--camel-box-text);
  background-image: url('assets/images/day24.svg');
  display: flex;
  align-items: center;
  justify-content: center;
}

#calendar>div:nth-child(25) {
  grid-column: 6;
  grid-row: 5 / 7;
}

#calendar>div:nth-child(25) .front {
  background-color: var(--light-blue);
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  background-image: url('assets/images/day25.svg');
  text-align: center;
}

#calendar>div:nth-child(25) .front .day {
  margin-top: 25%;
}

.flip-container {
  perspective: 1000px;
  height: 100%;
}

.flip-container.enabled:hover .flipper {
  transform: rotateY(180deg);
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  height: 100%;
}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
  background-image: url('assets/images/day1.svg');
  background-repeat: no-repeat;
  background-position: center;
  font-size: var(--medium-size);
  line-height: var(--medium-size);
  color: var(--text);
  border-radius: 8px;
  font-family: 'recoleta_altbold';
  padding: 16px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 576px) {
  .back {
    background-size: contain;
  }
}

.disabled {
  cursor: default;
}

.calendar-modal {
  width: 90%;
  max-width: 765px;
  border-radius: 8px;
  background-color: var(--modal-background);
  margin: 70px auto;
  color: var(--text);
  text-align: center;
  position: relative;
  opacity: 0;
  transition: all 1.2s ease;
  z-index: 999;
}

@media (max-width: 576px) {
  .calendar-modal {
    padding: 20px 30px 30px;
  }
}

@media (min-width: 577px) {
  .calendar-modal {
    padding: 20px 30px 30px;
  }
}

@media (min-width: 768px) {
  .calendar-modal {
    padding: 40px 60px 60px;
  }
}


.calendar-modal.fade-in {
  opacity: 1;
}

.calendar-modal-overlay .close-button {
  width: 38px;
  height: 38px;
  background-image: url('assets/images/close.svg');
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: -50px;
  cursor: pointer;
}

.calendar-modal .day {
  background-image: url('assets/images/day1.svg');
  background-size: 80px;
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: center;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  font-family: 'recoleta_altbold';
  font-size: var(--small-size);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.calendar-modal img {
  width: 100%;
  height: 100%;
}

.calendar-modal h2 {
  font-size: 30px;
  font-family: 'recoletamedium';
}

.calendar-modal-description {
  font-size: 20px;
  letter-spacing: .5px;
  margin-bottom: 30px;
}

.calendar-modal-overlay {
  position: fixed;
  display: block;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.viewed-front {
  filter: grayscale(1);
  opacity: .7;
}